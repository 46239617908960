import {
  gql,
  useQuery,
} from '@apollo/client';
import { setTenant } from '@slices/tenant';
import { useDispatch } from 'react-redux';
import orderBy from 'lodash/orderBy';
import ErrorPage from 'next/error';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTenant } from '@lib/hooks';
import { modules } from '@config';
import { NAV_MENU_ITEM_GRAPHQL_SCHEMA } from '@components/MainSidebarItem';

export default function AppBaseProvider({ host, children, fallback }) {
  const dispatch = useDispatch();
  const { tenant } = useTenant();
  const { data, error, loading } = useQuery(BASE_QUERY, {
    variables: {
      host,
    },
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.tenants.data.length > 0) {
      dispatch(setTenant(data.tenants.data[0]));
    }
  }, [data, dispatch]);

  if (loading) {
    return fallback;
  }

  if (error) {
    console.error(error);
  }

  if (!tenant) {
    return (
      <ErrorPage
        statusCode={404}
        title={`Host "${host}" nicht gefunden\n`}
      />
    );
  }

  return children;
}

AppBaseProvider.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node.isRequired,
};

const BASE_QUERY = gql`
  query Fetch($host: String!) {
    tenants(filters: { host: { eq: $host } }) {
      data {
        id
        attributes {
          host
          name
          brandThemeLight
          brandThemeDark
          brandLogoLight {
            data {
              attributes {
                name
                url
                formats
                width
                height
              }
            }
          }
          brandLogoDark {
            data {
              attributes {
                name
                url
                formats
                width
                height
              }
            }
          }
          favicons {
            data {
              id
              attributes {
                url
                width
                height
                ext
              }
            }
          }
          modules {
            __typename
            ${getTenantModules(modules)}
          }
        }
      }
    }
  }
`;

function getTenantModules(mods) {
  const groupedModules = orderBy(mods.flatMap((module) => Object
    .entries(module.modules ?? {})
    .map(([type, subModule]) => ({
      ...subModule,
      type,
    })))
    .reduce((accumulator, currentValue) => {
      const { type, navMenuItems } = currentValue;
      const container = accumulator.find((a) => a.type === type);

      if (container) {
        container.navMenuItems.push(...navMenuItems);
      } else {
        accumulator.push({
          ...currentValue,
        });
      }

      return accumulator;
    }, [])
    .map((module) => ({
      ...module,
      // Reduce nav items to unique values
      navMenuItems: orderBy((module.navMenuItems ?? []).reduce((accumulator, currentValue) => {
        if (accumulator.find((a) => a.attribute === currentValue.attribute)) {
          return accumulator;
        }

        return [...accumulator, currentValue];
      }, []), ['attribute']),
    })), ['type']);

  return groupedModules.map(({ type, graphQl, navMenuItems }) => `
  ... on ${type} {
    id
    ${graphQl ?? ''}
    ${navMenuItems.map(({ attribute }) => `
    ${attribute} {
      ${NAV_MENU_ITEM_GRAPHQL_SCHEMA}
    }
    `).join('')}
  }
  `).join('');
}
