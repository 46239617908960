import { gql, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

// eslint-disable-next-line import/prefer-default-export
export function useCurrentEvent() {
  const router = useRouter();
  const { data, ...props } = useQuery(FETCH_EVENT, {
    variables: {
      id: router.query.eventid || '0',
    },
  });

  useEffect(() => {
    window.addEventListener('moduleSettingUpdated', props.refetch);

    return () => {
      window.removeEventListener('moduleSettingUpdated', props.refetch);
    };
  }, [props.refetch]);

  if (props.error) {
    console.error(props.error);
  }

  const event = data?.event.data;
  const moduleSettings = data?.moduleSettings.data ?? [];

  return [event, moduleSettings, props];
}

const FETCH_EVENT = gql`
  query FetchEvent($id: ID!) {
    event(id: $id) {
      data {
        id
        attributes {
          isReadOnly
          date
          progress
          customer
          customerNumber
          customerContact
          externalReferenceId
          hubSpotDealUrl
          hubSpotTicketUrl
          asanaTicketUrl
          functionFolderUrl
          defaultLocale
          isWelcomeNotificationSent
          managing_user {
            data {
              id
              attributes {
                name
                email
                companyPosition
                contactPhone
                publicNotes
                profileImage {
                  data {
                    id
                    attributes {
                      url
                      formats
                    }
                  }
                }
              }
            }
          }
          location {
            data {
              id
              attributes {
                name_de
                name_en
                pictures(pagination: { limit: 100 }) {
                  image {
                    data {
                      id
                      attributes {
                        url
                        formats
                      }
                    }
                  }
                  rooms(pagination: { limit: 100 }) {
                    data {
                      id
                    }
                  }
                  occasion_groups(pagination: { limit: 100 }) {
                    data {
                      id
                    }
                  }
                }
              }
            }
          }
          customerLogo {
            data {
              id
              attributes {
                url
                formats
              }
            }
          }
          tpl_tasklist {
            data {
              id
            }
          }
          tpl_form_checklist {
            data {
              id
              attributes {
                type
              }
            }
          }
          days(
            pagination: { limit: 100 },
            sort: "date:asc"
          ) {
            data {
              id
              attributes {
                date
                time
                timeEnd
                occasion_group {
                  data {
                    id
                    attributes {
                      name_de
                      name_en
                    }
                  }
                }
                occasion
                paxMin
                paxMax
                isHidden
                isPrimary
                rooms(pagination: { limit: 100 }) {
                  data {
                    id
                    attributes {
                      time
                      timeEnd
                      paxMin
                      paxMax
                      note
                      room {
                        data {
                          id
                          attributes {
                            name_de
                            name_en
                          }
                        }
                      }
                    }
                  }
                }
                tpl_form_details {
                  data {
                    id
                    attributes {
                      type
                    }
                  }
                }
                tpl_form_equipment {
                  data {
                    id
                    attributes {
                      type
                    }
                  }
                }
                tpl_form_catering {
                  data {
                    id
                    attributes {
                      type
                    }
                  }
                }
                tpl_form_catering_beverages {
                  data {
                    id
                    attributes {
                      type
                    }
                  }
                }
                tpl_form_catering_selection {
                  data {
                    id
                    attributes {
                      type
                    }
                  }
                }
                tpl_form_additional_services {
                  data {
                    id
                    attributes {
                      type
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    moduleSettings: eventModuleSettingsEx(eventId: $id) {
      data {
        id
        attributes {
          name
          accessibility
          isReadOnly
        }
      }
    }
  }
`;
