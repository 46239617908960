import {
  FiAlignLeft,
  FiCheckSquare,
  FiClipboard,
  FiClock,
  FiCoffee,
  FiFile,
  FiFileText,
  FiGrid,
  FiInfo,
  FiMessageSquare,
  FiMic,
  FiPackage,
  FiPlusSquare,
} from 'react-icons/fi';
import {
  BiBaguette,
  BiChair,
  BiListCheck,
  BiRestaurant,
} from 'react-icons/bi';
import { NAV_MENU_ITEM_GRAPHQL_SCHEMA } from '@components/MainSidebarItem';
import MenuItemAccessibilityGuard from './components/MenuItemAccessibilityGuard';

import resources from './i18n';

export const rootPath = '/event';

const config = {
  name: 'event',
  rootPath,
  i18n: {
    resources,
  },
  modules: {
    ComponentTenantModuleEventBasic: {
      // Override the menu configuration below with custom labels & icons set in the backend
      navMenuItems: [
        {
          attribute: 'navItemDashboard',
        },
      ],
    },
    ComponentTenantModuleEventTask: {
      navMenuItems: [
        {
          attribute: 'navItemTasks',
        },
      ],
    },
    ComponentTenantModuleEventSchedule: {
      navMenuItems: [
        {
          attribute: 'navItemSchedule',
        },
      ],
    },
    ComponentTenantModuleEventForm: {
      graphQl: `
        formGroups {
          id
          module
          label_de
          label_en
          navItemGroup {
            ${NAV_MENU_ITEM_GRAPHQL_SCHEMA}
          }
          forms {
            module
            label_de
            label_en
            legacy_pathSegment
            navItemForm {
              ${NAV_MENU_ITEM_GRAPHQL_SCHEMA}
            }
          }
        }
      `,
    },
    ComponentTenantModuleEventSeating: {
      navMenuItems: [
        {
          attribute: 'navItemSeating',
        },
      ],
    },
    ComponentTenantModuleEventOrder: {
      navMenuItems: [
        {
          attribute: 'navItemOrder',
        },
      ],
    },
    ComponentTenantModuleEventInvoice: {
      navMenuItems: [
        {
          attribute: 'navItemInvoice',
        },
      ],
    },
    ComponentTenantModuleEventFile: {
    },
  },
  menu: {
    getSections: (t, i18n, router, tenant) => (!router.query.eventid
      ? []
      : [
        {
          title: t('modules.event.title'),
          acl: [
            'api::event.event.findOne',
            'api::event.event-day.find',
            'api::event.event-day.findOne',
            'api::event.event-day-room.find',
            'api::event.event-day-room.findOne',
          ],
          items: [
            {
              module: 'dashboard',
              title: t('modules.event.menu.dashboard'),
              path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}`,
              type: 'ComponentTenantModuleEventBasic',
              icon: <FiGrid />,
              attribute: 'navItemDashboard',
              acl: [
                'api::event.event.findOne',
              ],
            },
            {
              module: 'tasks',
              title: t('modules.event.menu.tasks'),
              path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/tasks`,
              type: 'ComponentTenantModuleEventTask',
              icon: <FiCheckSquare />,
              attribute: 'navItemTasks',
              acl: [
                'api::event.event.findOne',
                'api::event-task.event-task.find',
              ],
              wrapperComponent: ({ children }) => (
                <MenuItemAccessibilityGuard name="tasks">
                  {children}
                </MenuItemAccessibilityGuard>
              ),
            },
            {
              module: 'schedule',
              title: t('modules.event.menu.schedule'),
              path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/schedule`,
              type: 'ComponentTenantModuleEventSchedule',
              icon: <FiClock />,
              attribute: 'navItemSchedule',
              acl: [
                'api::event.event.findOne',
                'api::event-schedule.event-schedule.find',
              ],
              wrapperComponent: ({ children }) => (
                <MenuItemAccessibilityGuard name="schedule">
                  {children}
                </MenuItemAccessibilityGuard>
              ),
            },
            ...createFormNavMenuItems(t, i18n, router, tenant),
            {
              module: 'seating',
              title: t('modules.event.menu.seating'),
              path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/seating`,
              type: 'ComponentTenantModuleEventSeating',
              icon: <BiChair />,
              attribute: 'navItemSeating',
              acl: [
                'api::event.event.findOne',
                'api::event-seating.event-seating.find',
              ],
              wrapperComponent: ({ children }) => (
                <MenuItemAccessibilityGuard name="seating">
                  {children}
                </MenuItemAccessibilityGuard>
              ),
            },
            /* {
              module: 'services',
              title: t('modules.event.menu.serviceProvider'),
              path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/services`,
              icon: <FiUsers />,
              acl: [
                'api::event.event.findOne',
              ],
              wrapperComponent: ({ children }) => (
                <MenuItemAccessibilityGuard name="services">
                  {children}
                </MenuItemAccessibilityGuard>
              ),
            }, */
            {
              module: 'order',
              title: t('modules.event.menu.orderOverview'),
              path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/order`,
              type: 'ComponentTenantModuleEventOrder',
              icon: <FiPackage />,
              attribute: 'navItemOrder',
              acl: [
                'api::event.event.findOne',
                'api::event-upload.event-upload.find',
              ],
              wrapperComponent: ({ children }) => (
                <MenuItemAccessibilityGuard name="order">
                  {children}
                </MenuItemAccessibilityGuard>
              ),
            },
            {
              module: 'invoices',
              title: t('modules.event.menu.invoices'),
              path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/invoices`,
              type: 'ComponentTenantModuleEventInvoice',
              icon: <FiFile />,
              acl: [
                'api::event.event.findOne',
                'api::event-upload.event-upload.find',
              ],
              wrapperComponent: ({ children }) => (
                <MenuItemAccessibilityGuard name="invoices">
                  {children}
                </MenuItemAccessibilityGuard>
              ),
            },
            {
              module: 'files',
              title: t('modules.event.menu.files'),
              path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/files`,
              type: 'ComponentTenantModuleEventFile',
              icon: <FiFile />,
              attribute: 'navItemFiles',
              acl: [
                'api::event.event.findOne',
                'api::event-file.event-file.find',
              ],
              wrapperComponent: ({ children }) => (
                <MenuItemAccessibilityGuard name="files">
                  {children}
                </MenuItemAccessibilityGuard>
              ),
            },
          ],
        },
        {
          title: t('modules.event.titleIntern'),
          acl: [
            'api::event.event.findOne',
            'api::event.event.isEmployee',
          ],
          items: [
            {
              module: 'checklist',
              title: t('modules.event.menu.checklist'),
              path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/checklist`,
              icon: <BiListCheck />,
              type: 'ComponentTenantModuleEventBasic',
              acl: [
                'api::event.event.findOne',
                'api::event-form-field-value.event-form-field-value.find',
                'api::event.event.isEmployee',
              ],
              wrapperComponent: ({ children }) => (
                <MenuItemAccessibilityGuard name="checklist">
                  {children}
                </MenuItemAccessibilityGuard>
              ),
            },
            {
              module: 'function',
              title: t('modules.event.menu.function'),
              path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/function`,
              type: 'ComponentTenantModuleEventFunctionSheet',
              icon: <FiFileText />,
              acl: [
                'api::event.event.findOne',
                'api::event.event.isEmployee',
                'api::function-sheet.function-sheet.find',
              ],
              wrapperComponent: ({ children }) => (
                <MenuItemAccessibilityGuard name="function">
                  {children}
                </MenuItemAccessibilityGuard>
              ),
            },
            {
              module: 'notes',
              title: t('modules.event.menu.notes'),
              path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/notes`,
              icon: <FiInfo />,
              type: 'ComponentTenantModuleEventBasic',
              acl: [
                'api::event.event.findOne',
                'api::event.event.isEmployee',
              ],
              wrapperComponent: ({ children }) => (
                <MenuItemAccessibilityGuard name="notes">
                  {children}
                </MenuItemAccessibilityGuard>
              ),
            },
            {
              module: 'feedback',
              title: t('modules.event.menu.feedback'),
              path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/feedback`,
              type: 'ComponentTenantModuleEventFeedback',
              icon: <FiMessageSquare />,
              acl: [
                'api::event.event.findOne',
                'api::event.event.isEmployee',
                'api::event-feedback.event-feedback-note.find',
              ],
              wrapperComponent: ({ children }) => (
                <MenuItemAccessibilityGuard name="feedback">
                  {children}
                </MenuItemAccessibilityGuard>
              ),
            },
            {
              module: 'changelog',
              title: t('modules.event.menu.changelog'),
              path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/changelog`,
              icon: <FiAlignLeft />,
              type: 'ComponentTenantModuleEventBasic',
              acl: [
                'api::event.event.findOne',
                'api::event.event.isEmployee',
                'api::event-changeset.event-changeset.find',
              ],
              wrapperComponent: ({ children }) => (
                <MenuItemAccessibilityGuard name="changelog">
                  {children}
                </MenuItemAccessibilityGuard>
              ),
            },
          ],
        },
      ]),
  },
};

export default config;

function createFormNavMenuItems(t, i18n, router, tenant) {
  const { modules } = tenant.attributes;
  // eslint-disable-next-line no-underscore-dangle
  const formModule = modules.find((m) => m.__typename === 'ComponentTenantModuleEventForm');

  if (!formModule) {
    return [];
  }

  const { formGroups } = formModule;
  const menuItems = [];

  formGroups.forEach((formGroup) => {
    if (formGroup.forms.length > 1) {
      // Create a group to wrap subitems
      menuItems.push({
        module: formGroup.module,
        title: formGroup.navItemGroup?.[`label_${i18n.language}`] || formGroup[`label_${i18n.language}`],
        path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/${formGroup.module}`,
        type: 'ComponentTenantModuleEventForm',
        icon: ICON_MAPPING[formGroup.navItemGroup?.predefinedIconName] ?? <FiClipboard />,
        acl: [
          'api::event.event.findOne',
          'api::event-form-field-value.event-form-field-value.find',
        ],
        wrapperComponent: ({ children }) => (
          <MenuItemAccessibilityGuard name={formGroup.module}>
            {children}
          </MenuItemAccessibilityGuard>
        ),
        children: formGroup.forms.map((form) => ({
          module: form.module,
          title: form.navItemForm?.[`label_${i18n.language}`] || form[`label_${i18n.language}`],
          path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/${form.legacy_pathSegment}`,
          type: 'ComponentTenantModuleEventForm',
          icon: ICON_MAPPING[form.navItemForm?.predefinedIconName] ?? <FiClipboard />,
          acl: [
            'api::event.event.findOne',
            'api::event-form-field-value.event-form-field-value.find',
          ],
          wrapperComponent: ({ children }) => (
            <MenuItemAccessibilityGuard name={form.module}>
              {children}
            </MenuItemAccessibilityGuard>
          ),
        })),
      });
    } else if (formGroup.forms.length === 1) {
      const form = formGroup.forms[0];

      menuItems.push({
        module: form.module,
        title: form.navItemForm?.[`label_${i18n.language}`] || form[`label_${i18n.language}`],
        path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/${form.legacy_pathSegment}`,
        type: 'ComponentTenantModuleEventForm',
        icon: ICON_MAPPING[form.navItemForm?.predefinedIconName] ?? <FiClipboard />,
        acl: [
          'api::event.event.findOne',
          'api::event-form-field-value.event-form-field-value.find',
        ],
        wrapperComponent: ({ children }) => (
          <MenuItemAccessibilityGuard name={form.module}>
            {children}
          </MenuItemAccessibilityGuard>
        ),
      });
    } else {
      console.warn(`Form group ${formGroup.id} has no forms.`);
    }
  });

  return menuItems;

  /* return [
    {
      module: 'details',
      title: t('modules.event.menu.detailedAgreement'),
      path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/details`,
      type: 'ComponentTenantModuleEventForm',
      icon: <FiClipboard />,
      acl: [
        'api::event.event.findOne',
        'api::event-form-field-value.event-form-field-value.find',
      ],
      wrapperComponent: ({ children }) => (
        <MenuItemAccessibilityGuard name="details">
          {children}
        </MenuItemAccessibilityGuard>
      ),
    },
    {
      module: 'equipment',
      title: t('modules.event.menu.technicalAgreement'),
      path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/equipment`,
      type: 'ComponentTenantModuleEventForm',
      icon: <FiMic />,
      acl: [
        'api::event.event.findOne',
        'api::event-form-field-value.event-form-field-value.find',
      ],
      wrapperComponent: ({ children }) => (
        <MenuItemAccessibilityGuard name="equipment">
          {children}
        </MenuItemAccessibilityGuard>
      ),
    },
    {
      module: 'catering',
      title: t('modules.event.menu.catering'),
      path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/catering`,
      type: 'ComponentTenantModuleEventForm',
      icon: <FiCoffee />,
      acl: [
        'api::event.event.findOne',
        'api::event-form-field-value.event-form-field-value.find',
      ],
      wrapperComponent: ({ children }) => (
        <MenuItemAccessibilityGuard name="catering">
          {children}
        </MenuItemAccessibilityGuard>
      ),
      children: [
        {
          module: 'cateringAgreement',
          title: t('modules.event.menu.cateringAgreement'),
          path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/catering`,
          icon: <BiRestaurant />,
          acl: [
            'api::event.event.findOne',
            'api::event-form-field-value.event-form-field-value.find',
          ],
          wrapperComponent: ({ children }) => (
            <MenuItemAccessibilityGuard name="catering">
              {children}
            </MenuItemAccessibilityGuard>
          ),
        },
        {
          module: 'cateringBeverages',
          title: t('modules.event.menu.cateringBeverages'),
          path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/catering-beverages`,
          icon: <FiCoffee />,
          acl: [
            'api::event.event.findOne',
            'api::event-form-field-value.event-form-field-value.find',
          ],
          wrapperComponent: ({ children }) => (
            <MenuItemAccessibilityGuard name="catering">
              {children}
            </MenuItemAccessibilityGuard>
          ),
        },
        {
          module: 'cateringAgreement',
          title: t('modules.event.menu.cateringSelection'),
          path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/catering-food`,
          icon: <BiBaguette />,
          acl: [
            'api::event.event.findOne',
            'api::event-form-field-value.event-form-field-value.find',
          ],
          wrapperComponent: ({ children }) => (
            <MenuItemAccessibilityGuard name="catering">
              {children}
            </MenuItemAccessibilityGuard>
          ),
        },
      ],
    },
    {
      module: 'additional_services',
      title: t('modules.event.menu.additionalServices'),
      path: `${rootPath}/${router.query.eventid}/${router.query.dayindex}/additional-services`,
      type: 'ComponentTenantModuleEventForm',
      icon: <FiPlusSquare />,
      acl: [
        'api::event.event.findOne',
        'api::event-form-field-value.event-form-field-value.find',
      ],
      wrapperComponent: ({ children }) => (
        <MenuItemAccessibilityGuard name="additional_services">
          {children}
        </MenuItemAccessibilityGuard>
      ),
    },
  ]; */
}

const ICON_MAPPING = {
  FiClipboard: <FiClipboard />,
  FiMic: <FiMic />,
  BiRestaurant: <BiRestaurant />,
  FiCoffee: <FiCoffee />,
  BiBaguette: <BiBaguette />,
  FiPlusSquare: <FiPlusSquare />,
};
